.edit {
  width: 96%;
  height: 60px;
  line-height: 13px;
  word-wrap: break-word;
  word-break: break-all;
  border: 0;
  color: #00000075;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:focus {
    background: white;
    height: 100%;
    min-height: 60px;
    color: #000;
    -webkit-box-shadow: 0px 0px 23px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 23px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 23px -1px rgba(0, 0, 0, 0.75);
    border-radius: 3px;
    border: 1px solid #666;
    position: relative;
    text-overflow: initial;
    white-space: initial;
    overflow: auto;
  }
}
