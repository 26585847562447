.order-container {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 100%;
  min-height: 100%;
}

.side-panel {
  flex: 0 0 auto;
  position: relative;
  padding: 10px;
  background-color: #eee;
  min-height: 100%;
  width: 300px;
  height: 100%;
  overflow: scroll;
  h2 {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
  p {
    margin: 0;
    line-height: normal;
  }
}
.content-panel {
  flex: 200px;
  background-color: #444;
  height: 100%;
  color: #fff;
  position: relative;
  // padding: 20px;
  overflow: scroll;
}
.product-image {
  width: 320px;
}
.qr-reader {
  width: 100px;
}
select {
  width: 250px;
}

.order-status {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-right: 20px;
  height: 40px;
  position: relative;
  div {
    border: 2px solid white;
    background-color: #d9d9d9;
    color: #666666;
    position: relative;
    text-align: center;
    height: 40px;
    padding-left: 8px;
    font-size: 13px;
    vertical-align: middle;
    line-height: 36px;
    a:hover,
    a:visited,
    a:link,
    a:active {
      text-decoration: none;
      border-bottom: 0;
    }
  }

  div:not(:first-child) {
    padding-left: 16px;
  }

  div:not(:first-child):before {
    content: '';
    position: absolute;
    z-index: 1;
    right: calc(100% - 15px);
    top: 0;
    width: 0;
    height: 0;
    border-top: 18px solid transparent;
    border-left: 16px solid white;
    border-bottom: 18px solid transparent;
  }

  :after {
    content: '';
    position: absolute;
    z-index: 2;
    left: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 18px solid transparent;
    border-left: 16px solid #d9d9d9;
    border-bottom: 18px solid transparent;
  }

  .active {
    background-color: #f0da78;
  }

  .notFound {
    background-color: #f00078;
    color: white;
  }

  .notFound:after {
    border-left: 16px solid #f00078;
  }

  .active:after {
    border-left: 16px solid #f0da78;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-left: 16px solid white;
    border-bottom: 19px solid transparent;
    top: 0px;
    left: calc(100% - 20px);
  }
}

.done-overlay {
  z-index: 2;
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 50px 0 0 70px;
  pointer-events: none;
  p {
    text-align: center;
    text-transform: uppercase;
    transform: rotate(45deg);
    transform-origin: top left;
    display: inline-block;
    font-size: 6vw;
    &.fulfilled {
      color: rgba(255, 255, 255, 0.6);
      -webkit-text-stroke: 1px white;
    }
    &.rejected {
      -webkit-text-stroke: 1px red;
      color: rgba(255, 0, 0, 0.6);
    }
  }
}
