.coupon {
  border-bottom: 1px solid #f7f7f7;
  border-left: 1px solid #f7f7f7;
  border-right: 1px solid #f7f7f7;
  border-top: 1px solid #f7f7f7;
  line-height: 36px;
  list-style-type: disc;
  margin: 0px;
}
.coupon:hover {
  background: rgb(253, 241, 187) !important;
}
.coupon:nth-child(odd) {
  background: rgb(245, 244, 244);
}

.coupon-page {
  display: flex;
  overflow: hidden;
}

.coupon-data {
  visibility: hidden;
  height: 0px;
  list-style-type: none;
}

.coupon-data.show {
  height: auto;
  visibility: visible;
}

.coupon-form {
  width: 320px;
  background: #f7f7f7;
  padding: 8px 16px;
  // margin: 30px;

  display: inline-block;
}

.coupon-form fieldset {
  border: 0px;
  margin: 0px;
  padding: 0px;
  // margin-bottom: 0px;
}

.coupon-form fieldset label {
  width: 100%;
  display: inline-block;
  line-height: 1;
}

.coupon-form input {
  width: 100%;
  border: 1px solid #fbd0ec;
  border-radius: 3px;
  line-height: 24px;
  padding: 4px 8px;
  margin: 0px;
}

.coupon-form input[type="radio"]{
  max-width: 20px;
}

.coupon-form p {
  font-size: 11px;
  margin-left: 10px;
  line-height: 1;
}

.coupon-form button {
  margin-top: 20px;
  width: 100%;
  text-transform: uppercase;
  line-height: 30px;
  border-radius: 4px;
  background-color: #ff00aa;
  color: white;
  border: 2px solid transparent;

  &.secondary {
    background-color: grey;
  }

  &:hover {
    border: 2px solid #fbd0ec;
  }
}

.coupon-list {
  width: 100%;
  display: inline-block;
  .button {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
  }
}
