.taskbar {
  width: 100%;
  height: 60px;
  padding: 6px;
  background: #fff;
  border-bottom: 1px #d9dce0 solid;
  .header {
    color: #333;
    font-size: 10px;
    padding: 4px;
  }
}
.FulfillmentContentPanelAlbum {
  .editor {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    .jsoneditor-outer {
      height: calc(100vh - 64px - 75px);
    }
    .editorColumns {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .pages {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      align-items: flex-start;
      select {
        height: 50px;
        margin: 8px;
        font-size: 16px;
        color: #222;
      }
      button {
        width: 140px;
        height: 50px;
        &.selected {
          background: #00bcd4;
        }
      }
    }
    .FulfillmentContentPanelAlbum__jsonEditor {
      width: 100%;
    }
    .jsoneditor {
      border: 0;
      border-left: thin solid #fdf7e6;
    }
  }
  .preview {
    height: calc(100vh - 64px - 75px);
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__jsonEditor {
    background-color: #f8f9fa;
  }

  &__navbutton {
    border: 0;
    &:hover,
    &:focus,
    &:active {
      background-color: #222;
      color: #fff;
    }
  }
}
