.detail-wrapper {
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  display: none;
  z-index: 200;
}

.detail-wrapper.true {
  display: flex;
  opacity: 1;
}

.detail-wrapper h2 {
  text-align: left;
}
.view-wrapper {
  display: flex;
  .full {
    width: 100%;
  }
  .half {
    width: 50%;
  }
  @media (max-width: 1280px) {
    flex-direction: column;
    .half {
      width: 100%;
    }
  }
}
.detail-container {
  padding-top: 10px;
  border-radius: 4px;
  position: relative;
  width: 81%;
  overflow: hidden;
  background: #f7f7f7;
  display: block;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.4);
  .detail-content {
    position: relative;
    height: 500px;
    overflow: hidden;
    overflow-y: scroll;
    background: #fff;
    display: block;
    padding: 0px 10px 10px 10px;
    justify-content: center;
    flex-direction: column;

    .griddle table {
      min-height: auto !important;
    }
    .link {
      width: auto !important;
    }
  }
}

.detail-wrapper .header {
  text-align: left;
  padding-left: 10px;
  border-width: 1px;
  background: #f7f7f7;
  border: none;
}

.close {
  top: 5px;
  font-weight: 100;
  min-width: auto;
  background: none;
  border: 0px;
  color: #000;
  font-size: 54px;
  position: absolute;
  right: 0px;
  &:hover {
    background: none;
    color: #41c3b7;
  }
}

.detail-overview {
  .header {
    background-color: rgb(247, 247, 247);
  }
  ul {
    list-style-type: none;
    padding: 0px;
    margin-right: 35px;
  }
  .selected {
    background: #fff;
    color: #607d8b;
    -webkit-box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.33);
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.33);
  }
  .tab {
    min-width: auto;
    line-height: 19px;
    height: 30px;
  }
  .total {
    display: none;
  }
  .react-grid-Grid {
    height: calc(75vh - 125px);
  }
  .react-grid-row-group div strong {
    margin-left: -43px;
  }

  .user-name {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 800;
  }
  .innitials {
    background: #e91e63;
    border-radius: 40px;
    color: white;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    letter-spacing: -1px;
    margin-right: 10px;
  }
  .detail-header {
    height: 48px;
    background: #000;
    color: #ff00aa;
    line-height: 48px;
    padding: 0px 20px;
  }
  .detail-header li {
    display: inline-block;
  }
  .detail-header a {
    font-size: 20px;
    color: #ff00aa;
  }
  .link {
    width: auto !important;
    padding: 3px 10px !important;
    float: right;
    margin: 11px;
  }
  ul,
  ol {
    text-align: left;
    margin: 0px;
  }
  > ul li,
  ol li {
    border-bottom: 1px solid #f6f6f6;
    border-right: 1px solid #f6f6f6;
  }
  > ul li:first-child,
  > ul li:last-child {
    border-top: 1px solid #f6f6f6;
  }
  ul li .label {
    padding: 10px 15px;
    color: #333;
    width: 200px;
    display: inline-block;
    height: 50px;
    text-transform: capitalize;
    margin-right: 10px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .select-date-picker {
    display: inline-block;
  }
  button :not(.ok, .cancel) {
    // margin-right: 4px;
    // margin-left: 4px;
    // min-width: 80px;
    // text-transform: uppercase;
    // letter-spacing: -0.6px;
    // font-weight: 500;
    // font-size: 10px;
    // float: none;
  }
  p {
    border-top: 1px solid #ddd;
    padding-top: 20px;
    font-size: 13px;
  }
  h2 {
    margin: 5px;
    padding: 0px;
  }
  .images li {
    width: 150px;
    display: inline-block;
  }
  .images li img {
    border: 1px solid #f7f7f7;
    width: 150px;
  }
  .controls {
    // text-align: center;
    background: #fff;
    border: 0px;
    width: 100%;
    display: inline-block;
  }
  .controls li {
    border: 0px;
    width: auto;
    display: inline-block;
    margin: 10px 10px 10px 0px;
  }
  .controls .link {
    float: none;
  }

  .logsContainer {
    padding-bottom: 30px;

    .logs {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      border-radius: 8px;
      background-color: white;
      margin: auto;
      .header {
        padding: 12px 18px;
        color: #333;
        font-weight: 600;
        letter-spacing: 2px;
      }

      > div {
        align-self: center;
        line-height: 2;
        padding: 12px 18px;
        height: 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.detail-container .modal-controls {
  display: none;
  background: #fff;
  padding: 20px 10px;
  height: 60px;
  width: 100%;
  z-index: 300;
  position: relative;
  bottom: 0px;
}

.show-payment ul li ul {
  margin-left: 180px;
  margin-top: -50px;
}

.show-payment ul li ul li {
  border: 0px;
}

.ShowPayment {
  ul li {
    .label {
      width: 600px;
    }
  }
  .griddle {
    min-height: 400px;
    table {
      min-height: auto;
      td {
        background: #fff;
      }
      tr:hover td {
        background: #fff;
      }
    }
  }
}
//For Reamaze module embed

.iframe {
  padding: 0 !important;
  width: 167% !important;
  transform: scale(0.6, 0.6);
  transform-origin: 0 0;
  .react-grid-Container {
    padding: 0 !important;
  }
}
